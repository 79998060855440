<script>
import { getFilterRoute } from './shop-list-utils'

export default {
	props: { sortByValue: String, possibleFilters: Array, type: String },
	data() {
		return {
			selected: {
				value: 'default',
				text: 'Orden por defecto',
			},
			values: [
				{ value: 'price-high-to-low', text: 'Precio - Mayor a Menor' },
				{ value: 'price-low-to-high', text: 'Precio - Menor a Mayor' },
				{ value: 'name-high-to-low', text: 'Nombre Z - A' },
				{ value: 'name-low-to-high', text: 'Nombre A - Z' },
				{ value: 'discount-high-to-low', text: 'Mayor descuento' },
				{ value: 'default', text: 'Orden por defecto' },
			],
			ready: false,
		}
	},
	methods: {
		getFilterRoute(type, value, added) {
			return getFilterRoute(this.$route, type, value, added)
		},
	},
	computed: {
		allItems() {
			let selected = this.values.find((v) => v.value == this.selected.value)
			let all = [...this.possibleFilters, selected, { value: 'default', text: 'Orden por defecto' }]
			all.sort((a, b) => a?.value.toLowerCase().localeCompare(b?.value.toLowerCase()))
			return all
		},
	},
	watch: {
		selected(newVal, oldVal) {
			if (!!newVal?.value && newVal?.value != oldVal?.value) {
				if (this.ready)
					this.$router.replace(this.getFilterRoute(this.type, newVal.value, newVal?.value != 'default'))
				else this.ready = true
			} else this.ready = true
		},
	},
	created() {
		this.selected = this.values.find((v) => v.value == this.sortByValue) || {
			value: 'default',
			text: 'Orden por defecto',
		}
	},
}
</script>

<template>
	<v-select
		v-model="selected"
		:items="allItems"
		:class="[$bem('shop-sort-select'), $b.td && $bem('__desktop')]"
		label="Ordenar por"
		item-text="text"
		item-value="value"
		append-icon="mdi-chevron-down"
		return-object
		hide-details
		hide-selected
		outlined
		dense
	/>
</template>

<style scoped lang="scss">
.shop-sort-select {
	border-radius: 0;

	&__desktop {
		max-width: 260px;
	}
}
</style>
