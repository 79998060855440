<script>
import { getFilterRoute } from './shop-list-utils'
export default {
	props: {
		collapsable: Boolean,
		title: String,
		items: Array,
		type: String,
		limit: {
			type: Number,
			default: 12,
		},
		noAutoOrder: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dialog: false,
			collapsed: this.collapsable,
		}
	},
	inject: ['shop'],
	computed: {
		titleWithCap() {
			if (this.title) {
				const firstLetter = this.title.charAt(0).toUpperCase()
				const rest = this.title.slice(1)
				return firstLetter + rest
			}
			return null
		},
		listItems() {
			let items = [...(this.items || [])]
			if (this.noAutoOrder) return items.slice(0, this.limit)
			return items
				.sort((a, b) => {
					if (a.count == b.count) {
						return a.text > b.text ? 1 : -1
					} else {
						return (b.count || 0) - (a.count || 0)
					}
				})
				.slice(0, this.limit)
		},
		itemsByLetter() {
			if (this.items?.length <= this.limit) return null
			let items = [...this.items].sort((a, b) => {
				return a.text > b.text ? 1 : -1
			})
			return items.reduce((obj, item) => {
				let letter = item.text.slice(0, 1).toUpperCase()
				if (!obj[letter]) obj[letter] = []
				obj[letter].push(item)
				return obj
			}, {})
		},
		cShop() {
			return this.shop()
		},
	},
	methods: {
		getFilterRoute(type, value, added) {
			return getFilterRoute(this.$route, type, value, added, this.cShop)
		},
		doCollapse() {
			if (!this.collapsable) return
			this.collapsed = !this.collapsed
		},
	},
}
</script>

<template>
	<div :class="collapsable ? 'mt-3' : 'mt-6'" v-if="listItems.length">
		<div class="text-h6 font-weight-bold d-flex justify-space-between" v-if="title" @click="doCollapse">
			<div>{{ titleWithCap }}</div>
			<div>
				<v-icon v-if="collapsable">{{ collapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
			</div>
		</div>
		<div v-if="!collapsed">
			<div v-for="(item, i) of listItems" :key="i" class="py-1">
				<Button
					:color="item.color || 'link'"
					:text="!item.color"
					:style="{ backgroundColor: item.color }"
					:dark="!!item.color"
					:to="getFilterRoute(type, item.value, true)"
					class="px-2"
					:class="{ 'pr-6': $b.mt }"
					block
				>
					<div class="d-flex align-center justify-space-between w100">
						<div class="line-clamp-2 text-start pr-2 w100" style="line-height: 1rem">
							{{ item.text }}
						</div>
						<div v-if="!!item.displayValue">
							<div class="color-circle" :style="{ backgroundColor: item.displayValue }"></div>
						</div>
						<Media :src="item.displayMedia" width="24" v-if="!!item.displayMedia" />
						<div style="opacity: 0.5" v-if="item.count" class="ml-1">({{ item.count }})</div>
					</div>
				</Button>
			</div>
			<div v-if="itemsByLetter" class="py-1">
				<Button
					color="link"
					text
					@click="dialog = true"
					class="px-2 text-start justify-start"
					:class="{ 'pr-6': $b.mt }"
					block
				>
					<div class="d-flex align-center justify-space-between w100">
						<div class="line-clamp-2 text-start pr-2 w100" style="line-height: 1rem">
							Ver más opciones...
						</div>
						<v-icon>mdi-plus</v-icon>
					</div>
				</Button>
			</div>
		</div>
		<hr aria-hidden="true" class="divider mt-3" v-if="collapsable" />
		<v-dialog v-model="dialog" v-if="itemsByLetter">
			<div class="base px-8" style="positon: relative">
				<div
					class="base font-weight-bold font-2 mb-3 d-flex justify-space-between w100 cpointer py-5"
					style="position: sticky; top: 0"
					@click="dialog = false"
				>
					<span>{{ title }}</span>
					<v-icon color="link">mdi-close</v-icon>
				</div>
				<div v-for="(items, letter) in itemsByLetter" :key="letter" class="d-flex pb-3">
					<div class="font-weight-bold text-capitalize font-3 mr-5 pt-1">
						{{ letter }}
					</div>
					<div class="w100 pb-3 dialog-items-border">
						<div
							v-for="(item, i) of items"
							:key="i"
							class="font-1 cpointer py-2"
							@click="dialog = false"
						>
							<router-link :to="getFilterRoute(type, item.value, true)">
								<span class="mr-3">{{ item.text }}</span>
								<span class="grey--text">({{ item.count }})</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<style scoped lang="scss">
.divider {
	border: 0;
	border-bottom: 0.0625rem solid #e3e4e5;
	margin: 0;
	width: 100%;
}

.v-dialog__content ::v-deep .v-dialog {
	min-width: 400px;
	max-width: 600px;
	width: auto;
}
.dialog-items-border {
	border-bottom: 2px solid whitesmoke;
}
.cpointer {
	cursor: pointer;
}
.color-circle {
	height: 24px;
	width: 24px;
	border-radius: 100%;
	border: 1px dotted #999;
}

::v-deep .ratio-box__slot img {
	border-radius: 100%;
}
</style>

