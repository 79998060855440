<template>
	<div>
		<h1 class="header font-4 mb-4" ref="categoryTitle">{{ categoryDescription.category.name }}</h1>
		<div
			class="description-content hidden-text"
			ref="description"
			v-html="categoryDescription.description"
		></div>
		<div class="link--text pointer mt-2 d-inline-block" @click="toggleDescription">
			Ver {{ btnText }} <v-icon :color="$vars.linktext">{{ icon }}</v-icon>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		categoryId: Number,
		categoryDescription: Object,
	},
	data() {
		return {
			btnText: 'más',
			icon: 'mdi-chevron-up',
		}
	},
	methods: {
		toggleDescription() {
			const description = this.$refs.description.classList

			description.toggle('hidden-text')

			if (description.contains('hidden-text')) {
				this.btnText = 'más'
				this.icon = 'mdi-chevron-up'
			} else {
				this.btnText = 'menos'
				this.icon = 'mdi-chevron-down'
			}
		},
		scrollTo(target) {
			this.$refs[target].scrollIntoView({ behavior: 'smooth' })
		},
	},
}
</script>

<style lang="scss" scoped>
.hidden-text {
	max-height: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
